<template>
  <div class="grid">
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <loading
          :active="isLoadingModel"
          :can-cancel="true"
          color="#274461"
          :is-full-page="fullPage"
        ></loading>
        <Toast />
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
            >
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                Ticket Details
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
            >
              <Button
                class="p-button-warning"
                iconPos="right"
                label="Edit Ticket"
                icon="pi pi-pencil"
                v-tooltip.bottom="'Edit Ticket'"
                @click="Edit_Ticket"
                size="small"
                style="box-shadow: 0 2px 6px #ffd454;border-color: #ffc107!important;background-color: #ffc107!important;"
              />&nbsp;
              <Button
                label="Back"
                v-tooltip.bottom="'Back'"
                @click="Goback"
                size="small"
                style="box-shadow: 0 2px 6px rgba(0,176,176,.549);border-color: #00b0b0!important;background-color: #00b0b0!important;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-9">
          <div class="p-3 text-white bg-success ">
            <p style="color: white; font-size: 20px; font-weight: 800">
              {{ product.ticket_no }}
            </p>
          </div>
          <Card>
            <template #title> {{ row_data[0].title }} </template>
            <template #content>
              <p class="m-0">Description:</p>
              <p class="m-0">
                {{ convertHtmlToText(row_data[0].description) }}
              </p>
              <!-- <Editor v-model="row_data[0].description" editorStyle="height: 200px" /> -->
            </template>
          </Card>
          <div class="p-3 text-white bg-success2">
            <div class="col-2">
              <Button
                iconPos="left"
                icon="pi pi-comment"
                label="Post Reply"
                v-tooltip.bottom="'Post Reply'"
                @click="Post_Reply_view"
                class="p-button-warning p-button-outlined "
                size="small"
                id="b_post"
                style="color: white;"
              />
            </div>
          </div>
          <Card v-if="show_post_reply">
            <template #content>
              <div class="col-12">
                <div class="p-fluid formgrid grid">
                  <div class="field col-12 md:col-12">
                    <Editor v-model="post_reply_comment" editorStyle="height: 200px" />
                  </div>
                  <div class="field col-12 md:col-2">
                    <Button
                      label="Post Reply"
                      v-tooltip.bottom="'Post Reply'"
                      @click="Post_Reply()"
                      class="p-button-warning btn_yellow"
                      
                    />
                  </div>
                  <div class="field col-12 md:col-2">
                    <FileUpload
                class="p-button-warning btn_yellow"
                mode="basic"
                name="model[]"
                :auto="true"
                :custom-upload="true"
                choose-label="Attachment"
                @uploader="handleFileUpload($event)"
                
              />
                  </div>
                  <div class="field col-12 md:col-2">
                    <Button
                      label="Cancel"
                      v-tooltip.bottom="'Cancel'"
                      @click="cancel_Post_Reply_main"
                      class="p-button-danger btn_red"
                     
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
          <div v-if="row_data[0].ticket_comments.length > 0" style="height: 450px; overflow: scroll">
            <Card
              :key="index"
              v-for="(value, index) in row_data[0].ticket_comments"
              id="card_custom"
            >
              <template #content>
               
                <div class="grid">
                    <div class=" col-12 md:col-2">
                      <!-- {{ value }} -->
                      <img
                        style="border-radius: 50%; width: 80px; height: 80px"
                        v-if="value.customer_profile"
                        :src="value.customer_profile"
                        :alt="value.customer_profile"
                      />
                      <!-- <img
                        style="border-radius: 50%; width: 80px; height: 80px"
                        v-else
                        src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
                      /> -->
                      <span v-else class="i-circle" :style="'background-color:'+getcolorcode(value.username)">{{convert_name(value.username)}}</span>
                    </div>
                    <div class="col-12 md:col-8 text-left md:text-left">
                      <div>
                        <span style="font-size: 20px; font-weight: 700">{{
                          value.username
                        }}</span>
                      </div>
                      <div style="margin-top: 5px">
                        <span style="font-size: 15px; font-weight: 500">
                          {{ D_M_Y_formatDate(value.created_at) }}
                        </span>
                      </div>
                      <div style="margin-top: 20px" v-if="value['show_editor']==false || !value['show_editor']">
                        <span style="font-size: 15px; font-weight: 400">
                          {{ convertHtmlToText(value.comment) }}
                        </span>
                      </div>
                      <div style="margin-top: 10px" v-if="value.attachments && value.attachments.length>0">
                        <div :key="index" v-for="(info, index) in value.attachments">
                          Attachment {{index+1}} : <a  style="cursor: pointer;" @click="show_attachment(info)">view</a>
                        </div>
                        
                      </div>
                    </div>


                    <div class="col-12 md:col-2">
                  <div class="grid text-rigth md:text-right">
                    
                    <div class="col-12 md:col-12">
                      <Button
                        icon="pi pi-pencil"
                        v-tooltip.bottom="'Edit'"
                        class="p-button-rounded p-button-warning mt-2 btn_yellow"
                        @click="confirmeditpost(value,index)"
                      />&nbsp;
                      <Button
                        icon="pi pi-trash"
                        v-tooltip.bottom="'Delete'"
                        class="p-button-rounded p-button-danger mt-2 btn_red"
                        @click="confirmDeleteProduct(value,index)"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-12 md:col-12" v-if="value['show_editor']==true">
                  <Editor v-model="value['edited_comment']" editorStyle="height: 200px" />
                </div>
                
                <div class="field col-12 md:col-2" v-if="value['show_editor']==true">
                    <Button
                      label="Post Reply"
                      v-tooltip.bottom="'Post Reply'"
                      @click="edited_Post_Reply(value,index)"
                      class="p-button-warning btn_yellow"
                      
                    />
                  </div>
                  <div class="field col-12 md:col-2" v-if="value['show_editor']==true">
                    <FileUpload
                class="p-button-warning btn_yellow"
                mode="basic"
                name="model[]"
                :auto="true"
                :custom-upload="true"
                choose-label="Attachment"
                @uploader="handleFileUpload($event,1,index)"
                
              />
                  </div>
                  <div class="field col-12 md:col-2" v-if="value['show_editor']==true">
                    <Button
                      label="Cancel"
                      v-tooltip.bottom="'Cancel'"
                      @click="cancel_Post_Reply(value,index)"
                      class="p-button-danger btn_red"
                     
                    />
                  </div>



                  </div>
              
              </template>
            </Card>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div id="custom_card" class="card">
            <div class="col-12">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 800">
                    Ticket Details
                  </p>
                </div>
                <div class="field col-12 md:col-12">
                  <Dropdown
                    id="ticket_status"
                    v-model="ticket_status_selected"
                    :options="ticket_status_dropdown"
                    :value="ticket_status_dropdown._id"
                    optionLabel="name"
                    filter
                    @change="
                      update_status(product.ticket_no, ticket_status_selected)
                    "
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400;">
                    Customer:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700;word-wrap: break-word">
                    {{ row_data[0].customer_name }}
                  </p>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400;">
                    Email:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700;word-wrap: break-word">
                    {{ row_data[0].customer_email }}
                  </p>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Category:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700">
                    {{ row_data[0].ticket_category_name }}
                  </p>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Agents:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <!-- <p style="color: #6b719b; font-size: 15px; font-weight: 700">
                    {{
                      row_data[0].agent_details == ""
                        ? "N/A"
                        : row_data[0].agent_details[0].agent_name
                    }}
                  </p> -->
               
                  <AvatarGroup class="mb-2">
                                  <Avatar
                                    v-for="(info, index) in row_data[0].agent_details"
                                    :key="index"
                                    :image="info.agent_profile"
                                    size="small"
                                    shape="circle"
                                    @click="show_Agent(info,index)"
                                    v-tooltip.bottom="info.agent_name"
                                    style="cursor: pointer;"
                                    class="zoom"
                                  ></Avatar>
                                </AvatarGroup>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Created:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700">
                    {{ D_M_Y_formatDate(row_data[0].created_at) }}
                  </p>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Attachments:
                  </p>
                </div>
                <div class="field col-12 md:col-8"></div>
                <AvatarGroup class="mb-2">
                  <Avatar
                    v-for="(info, index) in row_data[0].attachments"
                    :key="index"
                    :image="info"
                    size="small"
                    shape="circle"
                    @click="show_Attchment(info,index)"
                    style="cursor: pointer; height: 50px;"
                    class="zoom"
                  ></Avatar>
                </AvatarGroup>
                <!-- <div class="field col-12 md:col-12">
                  <img
                    v-if="row_data[0].attachments"
                    :src="row_data[0].attachments"
                    
                    alt=""
                    class="zoom"
                    style="height: 50px; width: 50px"
                  />
                </div> -->
                <div class="field col-12 md:col-12">
                  <!-- <Button
                    id="b_delete"
                    label="Delete Ticket"
                    v-tooltip.bottom="'Delete Ticket'"
                    @click="DeleteTicket"
                    class="p-button-danger p-button-outlined "
                    size="small"
                  /> -->

                  <!-- ------------------ -->

                  <Button
                    id="b_delete"
                    label="Delete Ticket"
                    v-tooltip.bottom="'Delete Ticket'"
                    @click="delete_toggle(item)"
                    class="p-button-danger p-button-outlined "
                    size="small"
                  />

                  <OverlayPanel ref="del" >
                      <div class="flex flex-column gap-3 w-25rem">
                        <h5>Delete Ticket </h5>

                        <div class="col-12">
                          <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-12">
                              <span class="p-float-label">
                                <Textarea
                                    format="text"
                                    v-model="delete_remark"
                                  >
                                </Textarea>
                                <label>Remark</label>
                            </span>
                            </div>

                            <div class="field col-12 md:col-4">
                              <Button label="Delete" @click="delete_product"  class="p-button-primary btn_light_blue"/>
                            </div>
                            <div class="field col-12 md:col-4">
                              <Button label="Cancel" @click="Cancel_delete"  class="p-button-danger btn_red"/>
                            </div>

                          </div>
                        </div>

                      </div>
                  </OverlayPanel>

                  <!-- ------------------ -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollTop />
  </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import ColorService from "../service/ColorService";
export default {
  name: "EticketTicketDetails",
  data() {
    return {
      delete_remark:"",
      ColorService:null,
        color_data:[],
      show_post_reply: false,
      product: [],
      ticket_status_dropdown: [],
      ticket_status_selected: "",
      row_data: [],
      productService: null,
      isLoadingModel: false,
      fullPage: true,
      post_reply_comment:"",
      attachments:[],
      attachments_count:0,
    };
  },
  async mounted() {
    this.ColorService.getColors().then((colors) => {
			this.color_data = colors;
		 
		  });
  },
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.ColorService = new ColorService();
    if (localStorage.getItem("TicketDetails")) {
      this.product = await JSON.parse(localStorage.getItem("TicketDetails"));
    }
    await this.get_TicketStatusMaster();
    await this.Get_ticketDetails();
    this.ticket_status_selected = this.product.ticket_status;
    await localStorage.setItem("TicketDetails", "");
  },
  methods: {
    async show_Attchment(info,index){
      window.open(info);
    },
    async show_Agent(info,index)
    { 
     console.log(info);
     console.log(index);
    },
    DeleteTicket()
    {
      
      this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to Delete ticket?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {


                    var payload=
                    {
                      "client_id":"65a4f82f6177e69880ece5d6",
                      "user_id":localStorage.getItem("id"),
                      "ticket_no":this.product.ticket_no

                    }
        
                    this.isLoadingModel = true;
                    var promise = apis.deleteTicket(payload);
                    promise.then((response) => {
                        this.isLoadingModel = false;
                        if (response.data.status==true) 
                        {
                            this.$swal(response.data.message);
                            this.$router.push({ name: "tickets" });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            
                        }
                        else
                        {
                            this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
                        }
                    });

                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: 'Cancel', detail: 'You have Cancelled', life: 3000 });
                }
            });
        

    },

    
    delete_toggle() 
      { 
        this.$refs.del.toggle(event);
      },

      delete_product(){
     
        if (this.delete_remark==null || this.delete_remark=="" || this.delete_remark==undefined ) 
        {
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please Enter Remark",
              life: 3000,
          });
          return false;
        }
        if ( this.delete_remark.length>1000) 
        {
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please Enter Valid Remark",
              life: 3000,
          });
          return false;
        }
       

        else
        {
          var payload=
            {
              "client_id":"65a4f82f6177e69880ece5d6",
              "user_id":localStorage.getItem("id"),
              "ticket_no":this.product.ticket_no,
              "remark":this.delete_remark
            }

            this.isLoadingModel = true;
            var promise = apis.deleteTicket(payload);
            promise.then((response) => {
                this.isLoadingModel = false;
                if (response.data.status==true) 
                {
                    this.$swal(response.data.message);
                    this.delete_remark="";
                    this.$router.push({ name: "tickets" });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    
                }
                else
                {
                    this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
                }
            });
        }
      },

      Cancel_delete(){
        // this.temp_product_id="";
        this.delete_remark="";
        this.$refs.del.hide();
      },

    getcolorcode(name)
      {
        var name=this.convert_name(name)
        var temp=name.substring(0, 1);
        for (let index = 0; index < this.color_data.length; index++) 
        {
         if (this.color_data[index].name==temp) 
         {
            return this.color_data[index].code;
         }
          
        }
      },
    convert_name(name)
      {
        var x= this.ColorService.convertToSN(name);
        return x.toString();
        
      },

    async edited_Post_Reply(value,index) 
    {
        var details = 
        { 
            "Edited_comment":this.row_data[0].ticket_comments[index]['edited_comment'],
            "Edited_attachment":this.row_data[0].ticket_comments[index]['edited_attachment']
        }

        console.log("***********************");
        console.log(details);
    },
    async Post_Reply() 
    {
      var data = {
       
        "ticket_no": this.product.ticket_no,
        "client_id":"65a4f82f6177e69880ece5d6",
        "user_id":localStorage.getItem("id"),
        "comment":this.post_reply_comment,
        "attachments":this.attachments
        };
        var promise = apis.SendTicketComment(data);
        promise.then((response) => {
          
          this.post_reply_comment="";
          this.show_post_reply = false;
          this.attachments=[];
          this.attachments_count=0;
          this.$swal(response.data.message);
          this.Get_ticketDetails();
        });
    },
    async Post_Reply_view() {
      this.show_post_reply = true;
      
    },
    async cancel_Post_Reply_main() {
      this.show_post_reply = false;
      
    },
    async cancel_Post_Reply(value,index)
    {
      var data = this.row_data[0].ticket_comments;
      for (let i = 0; i < data.length; i++) 
      {
        
          data[i]['show_editor']=false;
          data[i]['edited_comment']="";
          data[i]['edited_attachment']=[];
        
        
      }
      this.row_data[0].ticket_comments = data;
    },
    async confirmeditpost(value,index)
    {
      var data = this.row_data[0].ticket_comments;
      for (let i = 0; i < data.length; i++) 
      {
        if (i===index) 
        {
          data[i]['show_editor']=true;
          data[i]['edited_comment']=data[i]['comment'];
          data[i]['edited_attachment']=data[i]['attachments'];
        } else 
        {
          data[i]['show_editor']=false;
          data[i]['edited_comment']="";
          data[i]['edited_attachment']=[];
        }
        
        
      }
      this.row_data[0].ticket_comments = data;
    },
    async Edit_Ticket() {
      var user_type = localStorage.getItem("user_type");
      await localStorage.setItem("TicketDetails", "");
      await localStorage.setItem(
        "TicketDetails",
        JSON.stringify(this.row_data)
      );
      if (user_type == "Admin") {
        this.$router.push({ name: "AdminEditTicket" });
      } else if (user_type == "Customer") {
        this.$router.push({ name: "CustomerEditTicket" });
      } else if (user_type == "Agent") {
        this.$router.push({ name: "AgentEditTicket" });
      } else {
        await localStorage.setItem("TicketDetails", "");
      }
    },
    convertHtmlToText(html) {
      var temporaryElement = document.createElement("div");
      temporaryElement.innerHTML = html;
      return temporaryElement.textContent || temporaryElement.innerText || "";
    },
    async Goback() {
      this.$router.push({ name: "tickets" });
    },
    D_M_Y_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "/" + mm + "/" + yyyy;
        // alert(dd)
        if (dd.toString() != "NaN") {
          console.log(new_date);
          return new_date;
        } else {
          return date_parm;
        }
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async get_TicketStatusMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
      };
      var promise = apis.TicketStatusMaster(data);
      promise.then((response) => {
        this.ticket_status_dropdown = response.data.data;
      });
    },
    async Get_ticketDetails() {
      var data = {
        ticket_no: this.product.ticket_no,
        client_id: "65a4f82f6177e69880ece5d6",
      };
      this.isLoadingModel = true;
      var promise = apis.getticketDetails(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.row_data = response.data.data;
      });
    },
    async update_status(ticket_no, status) {
      if (!ticket_no || !status) {
        this.$swal("Error !!!");
      }
      let details = {
        user_id: localStorage.getItem("id"),
        ticket_no: ticket_no,
        client_id: localStorage.getItem("client_id"),
        status: status._id,
      };
      var promise = apis.updateTicketStatus(details);
      promise.then((response) => {
        this.$swal(response.data.message);
      });
    },
    async show_attachment(url)
    {
      window.open(url);
    },
    async handleFileUpload(event,type,index) {
        var fileUp = event.files[0];
        var file = fileUp;
        this.file = file;
        var prfeix = await this.date_time_prefix();
        var filename = prfeix + "_" + file.name;
        this.file_data = {
          filePath: filename,
          contentType: file.type,
        };
        var promise = apis.upload_to_AWS(this.file_data);
        promise.then((response) => {
          axios
            .put(response.data.data, file, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": file.type,
              },
            })
            .then((response) => {
              if (type==1) 
              {
                this.row_data[0].ticket_comments[index]['edited_attachment'].push(
                "https://eticket-docs.s3.ap-south-1.amazonaws.com/" + filename
                );
              } else 
              {
                this.attachments.push(
                "https://eticket-docs.s3.ap-south-1.amazonaws.com/" + filename
                );
                this.attachments_count = this.attachments_count + 1;
            
              }
              });
        });
      },
      async date_time_prefix() {
        // Get the current date and time
        const currentDateTime = new Date();
        // Format the date as YYYY_MM_DD
        const formattedDate = currentDateTime
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "_");
        // Format the time as HH_mm_ss
        const formattedTime = currentDateTime
          .toTimeString()
          .split(" ")[0]
          .replace(/:/g, "_");
        // Construct the final string
        const resultString = `${formattedDate}_${formattedTime}`;
        return resultString;
      },
  },
};
</script>
<style  scoped>
#custom_card {
  border-top: 3px solid #6b719b;
}
#card_custom{
  border-bottom: 3px solid #6b719b;
}
.bg-success {
  background-color: #00b064 !important;
}
.bg-success2 {
  background-color: #00b0b0 !important;
}

.glow {
  font-size: 80px;
  color: #fff;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
}

#b_delete:hover{
  background-color: red;
  color:white
}

#b_post:hover{
  background-color: white;
  color:#00b0b0 !important
}
.zoom:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}
</style>